<template>
    <div class ='col-md-12 mb-4'>
        <div class = "row">
            <div class ='col-md-4'>
                <img class = 'thumb' :src='thumb'>
            </div>
            <div class ='col-md-8'>
                <h2 class = 'mb-3'><router-link :to='"/events/"+slug' class='button is-fullwidth'>{{title}}</router-link></h2>
                <h3>{{startDate}}</h3>
                <p>{{snippet}}</p>
            </div>
        </div>
     </div>
</template>

<script>
export default {
  name: 'EventCard',
  props: {
    title: String,
    slug: String,
    startDate: String,
    snippet: String,
    thumb: String
  }
}
</script>
