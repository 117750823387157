<template>
  <main id="main">
  <!-- Header -->
  <div class="container py-4 py-lg-4">
    <div class = 'col-md-12'>
        <div class="row" v-for='course in courses' :key="course.title">
              <course-card v-bind="course"></course-card>
        </div>
    </div>
  </div>
</main>
</template>

<style type="text/css">

</style>

<script>
import CourseService from '@/services/CourseService'
import CourseCard from '@/components/CourseCard'

export default {
  name: 'publications',
  components: {
    CourseCard
  },

  data () {
    return {
      airtableResponse: []
    }
  },

  mounted: function () {
    const self = this
    async function getCourses () {
      try {
        const response = await CourseService.getCourses()
        self.airtableResponse = response.data.records
      } catch (err) {
        console.log(err)
      }
    }
    getCourses()
  },

  computed: {
    courses () {
      const self = this
      const courseList = []
      for (var i = 0; i < self.airtableResponse.length; i++) {
        const course = {
          title: self.airtableResponse[i].fields.Title,
          desc: self.airtableResponse[i].fields.Description,
          url: self.airtableResponse[i].fields.Website,
          quarters: self.airtableResponse[i].fields.Quarters,
          thumb: self.airtableResponse[i].fields.Thumb[0].url
        }
        courseList.push(course)
      }
      return courseList
    }
  }
}
</script>
