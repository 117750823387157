<template>
  <div>

  </div>
</template>

<script>

export default {
  name: 'about'
}

</script>
