<template>
    <div class ='col-md-12 mb-4'>
        <div class = "row">
            <div class ='col-md-4'>
                <img class = 'thumb' :src='thumb'>
            </div>
            <div class ='col-md-8'>
                <h3><a :href='url'>{{title}}</a></h3>
                <p>{{desc}}</p>
            </div>
        </div>
     </div>
</template>

<script>

export default {
  name: 'PubCard',
  props: {
    title: String,
    desc: String,
    url: String,
    thumb: String,
    quarters: {
      type: Array
    }
  }

}
</script>
