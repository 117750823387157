<template>
    <div>
        {{data}}
    </div>
</template>

<script>
export default {
  name: 'Bibtex',
  props: {
    data: String
  }
}
</script>
