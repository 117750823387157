import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/Home.vue'
import News from './components/News.vue'
import About from './components/About.vue'
import Contacts from './components/Contact.vue'
import AllProjects from './components/AllProjects.vue'
import Project from './components/Project.vue'
import AllPeople from './components/AllPeople.vue'
import AllPublications from './components/AllPublications.vue'
import Publication from './components/Publication.vue'
import Bibtex from './components/Bibtex.vue'
import AllCourses from './components/AllCourses.vue'
import Openings from './components/Openings.vue'
import Residency22 from './components/Residency22.vue'
import Residency23 from './components/Residency23.vue'
import Punchprint from './components/Punchprint.vue'
import ArtistTalks from './components/Artisttalks.vue'
import Portfolio from './components/Portfolio.vue'
import NavShell from './components/NavShell.vue'
import CoilCAM from './components/CoilCAM.vue'
import Event from './components/Event.vue'
import Protected from './components/Protected.vue'
import NotFound from './components/NotFound.vue'
import Events from './components/Events.vue'
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'navshell',
      component: NavShell,
      children: [
        {
          path: '/',
          name: 'home',
          component: Home
        },
        {
          path: '/home',
          name: 'home',
          component: Home
        },
        {
          path: '/news',
          name: 'news',
          component: News
        },
        {
          path: '/about',
          name: 'about',
          component: About
        },
        {
          path: '/contact',
          name: 'contact',
          component: Contacts
        },
        {
          path: '/projects',
          name: 'projects',
          component: AllProjects
        },
        {
          path: '/publications',
          name: 'publications',
          component: AllPublications
        },
        {

          path: '/project/:slug',
          name: 'project',
          component: Project
        },
        {
          path: '/events',
          name: 'events',
          component: Events
        },
        {
          path: '/events/:slug',
          name: 'event',
          component: Event
        },
        {
          path: '/people',
          name: 'people',
          component: AllPeople
        },
        {
          path: '/courses',
          name: 'courses',
          component: AllCourses
        },
        {
          path: '/openings',
          name: 'openings',
          component: Openings
        }]
    },
    {
      path: '/publication/:slug',
      name: 'publication',
      component: Publication
    },
    {
      path: '/bibtex/:data',
      name: 'bibtex',
      props: true,
      component: Bibtex
    },
    {
      path: '/protected/',
      name: 'Protected',
      component: Protected
    },
    { path: '/404', component: NotFound },
    { path: '*', redirect: '/404' },
    {
      path: '/residency22',
      name: 'residency22',
      component: Residency22
    },
    {
      path: '/residency23',
      name: 'residency23',
      component: Residency23
    },
    {
      path: '/embroidery',
      name: 'embroidery',
      component: Punchprint
    }, {
      path: '/artisttalks',
      name: 'artisttalks',
      component: ArtistTalks
    }, {
      path: '/portfolio',
      name: 'portfolio',
      component: Portfolio
    }, {
      path: '/coilcam',
      name: 'CoilCAM',
      component: CoilCAM
    }]
})
