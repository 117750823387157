<template>
    <main id="main">
  <!-- Header -->
  <div class="container py-4 py-lg-4">
    <div class = 'col-md-12'>
        <div class = 'row'>
            <div class = 'col-md-4'>
                <div class = 'row'>
                    <div class ='col-md-12'>
                <h3>Mailing Address</h3>
                <p>Media Arts and Technology<br/>
                    3309 Phelps Hall<br/>
                    Santa Barbara, California<br/>
                93106-6065</p>

                <h3>Lab Location</h3>
                <p>
                    The Expressive Computation Lab is housed in the <a href="http://www.cnsi.ucsb.edu/">California Nano-Systems Institute</a> (Elings hall, Room 2024 — 2nd floor and left from the elevator) To contact an individual, see our <router-link to="/people">People</router-link> page.
                </p>
                <h3>Interested in Applying?</h3>
                <p>See information for <router-link to="/openings">prospective students here</router-link></p>
                <h3>Resources for Visitors</h3>
                <ul>
                    <li>
                        <a href="http://www.aw.id.ucsb.edu/maps">UCSB campus and area maps</a>
                    </li>
                    <li>
                        <a href="http://www.admissions.ucsb.edu/VisitUCSB.asp?section=visitucsb">UCSB Visitor Center</a>
                    </li>
                    <li>
                        <a href="http://www.cs.ucsb.edu/department/visiting/">Visitor information (travel to UCSB, parking, lodging, etc.)</a>
                    </li>
                    <li>
                        <a href="http://www.santabarbara.com/">Information about Santa Barbara</a>
                    </li>
                    <li>
                        <a href="http://www.weather.com/weather/local/93106?lswe=93106&amp;lwsa=WeatherLocalUndeclared">Santa Barbara Weather</a>
                    </li>
                </ul>
            </div>
        </div>
       <!--<div class = "row">
            <div class = "col-md-12">
                <h3>Sign up for our mailing list</h3>

                <form class="mailing-form" @submit.prevent="sendEmail">
                    <label>Name</label>
                    <input type="text" name="user_name">
                    <label>Email</label>
                    <input type="email" name="user_email">
                    <input type="submit" value="Send">
                </form>
                 <p>We use our mailing list to announce workshops, artist residency openings, exhibitions, and other events. We will never distribute your email to a third party and you can remove yourself at any time.</p>
            </div>
        </div>-->
            </div>
            <div class = 'col-md-6'>
                <div class = 'map'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3291.4387294308217!2d-119.84240338477535!3d34.41560938050866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e93f76c59b6817%3A0x73191f17be4fc14d!2sElings%20Hall%2C%20Building%20266%2C%20University%20of%20California%2C%20Santa%20Barbara%2C%20Santa%20Barbara%2C%20CA%2093106!5e0!3m2!1sen!2sus!4v1607406377925!5m2!1sen!2sus" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
</main>
</template>
<script>
import emailjs from 'emailjs-com'

export default {
  name: 'contact',
  methods: {
    sendEmail: (e) => {
      emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
        .then((result) => {
          console.log('SUCCESS!')
        }, (error) => {
          console.log('FAILED...', error)
        })
    }
  }
}

</script>
