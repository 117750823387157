<template>
   <div class ='col-md-12'>
        <h2>News</h2>
        <div class="row" v-for='newsItem in news' :key="newsItem.title">

                <div class ='col-md-2'>

                <p class = 'news'>{{newsItem.date}}</p>
        </div>
        <div class = 'col-md-10'>
                <p class = "news"><vue-simple-markdown :source=newsItem.text></vue-simple-markdown></p>
        </div>
        </div>
    </div>
</template>

<script>
import NewsService from '@/services/NewsService'
import DateFormatter from '@/services/DateFormatter'

export default {
  name: 'news',

  data () {
    return {
      airtableResponse: []

    }
  },

  mounted: function () {
    const self = this
    async function getNews () {
      try {
        const response = await NewsService.getNews()
        self.airtableResponse = response.data.records
      } catch (err) {
        console.log(err)
      }
    }

    getNews()
  },

  computed: {
    news () {
      const self = this
      const newsList = []
      for (var i = 0; i < self.airtableResponse.length; i++) {
        if (self.airtableResponse[i].fields.published) {
          const newsItem = {
            title: self.airtableResponse[i].fields.title,
            text: self.airtableResponse[i].fields.snippet,
            date: DateFormatter.getFormattedDate(self.airtableResponse[i].fields.date),
            numericDate: DateFormatter.getNumericDate(self.airtableResponse[i].fields.date)
          }
          newsList.push(newsItem)
        }
      }
      newsList.sort(function (a, b) {
        if (a.numericDate > b.numericDate) {
          return -1
        } else {
          return 1
        }
      })
      if (this.$route.name === 'news') {
        return newsList
      } else {
        return newsList.slice(0, 10)
      }
    }
  }
}

</script>
