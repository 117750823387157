<template>
  <main id="main">
  <!-- Header -->
  <div class="container py-4 py-lg-4">
    <div class = 'col-md-12'>
      <div class="row">
        <div class='col-md-12 mb-2'>
          <h4>ECL is made up of a comunity of researchers with backgrounds in art, music, design, and engineering. Learn more about us.</h4>
        </div>
      </div>
        <div class="row">
        <div class = 'col-md-3 mb-3' v-for='person in people[0]' :key="person.name">
              <people-card v-bind="person"></people-card>
        </div>
      </div>
      <div class="row">
        <div class='col-md-12 mb-2'>
          <h2>Alumni and Past Collaborators</h2>
        </div>
      </div>
        <div class="row">
        <div class = 'col-md-3 mb-3' v-for='person in people[1]' :key="person.name">
              <people-card v-bind="person"></people-card>
        </div>
      </div>
    </div>
  </div>
</main>
</template>

<style type="text/css">

</style>

<script>
import PeopleService from '@/services/PeopleService'
import PeopleCard from '@/components/PeopleCard'
// import json from '@/assets/data/projects.json'

export default {
  name: 'people',
  components: {
    PeopleCard
  },

  data () {
    return {
      airtableResponse: []
    }
  },

  mounted: function () {
    const self = this
    async function getPeople () {
      try {
        const response = await PeopleService.getPeople()
        self.airtableResponse = response.data.records
      } catch (err) {
        console.log(err)
      }
    }
    getPeople()
  },

  computed: {
    people () {
      const self = this
      var peopleListCurrent = []
      var peopleListAlumns = []
      var peopleList = []
      for (var i = 0; i < self.airtableResponse.length; i++) {
        var person = {
          name: self.airtableResponse[i].fields.Name,
          title: self.airtableResponse[i].fields.Title,
          image: (self.airtableResponse[i].fields.Image ? self.airtableResponse[i].fields.Image[0].url : ''),
          role: self.airtableResponse[i].fields.Role,
          web: [],
          research: self.airtableResponse[i].fields.Research,
          bio: self.airtableResponse[i].fields.Bio
        }

        if (self.airtableResponse[i].fields.Website) {
          person.web.push({
            link: self.airtableResponse[i].fields.Website,
            text: 'website'
          })
        }

        if (self.airtableResponse[i].fields.Email) {
          person.web.push({
            link: 'mailto:' + self.airtableResponse[i].fields.Email,
            text: 'email'
          })
        }
        if (self.airtableResponse[i].fields.Twitter) {
          person.web.push({
            link: self.airtableResponse[i].fields.Twitter,
            text: 'twitter'
          })
        }
        if (self.airtableResponse[i].fields.Instagram) {
          person.web.push({
            link: self.airtableResponse[i].fields.Instagram,
            text: 'instagram'
          })
        }
        if (self.airtableResponse[i].fields.LinkedIn) {
          person.web.push({
            link: self.airtableResponse[i].fields.LinkedIn,
            text: 'linkedin'
          })
        }
        // website: ,
        // email: 'mailto:' + self.airtableResponse[i].fields.Email,
        if (person.role === 'alumni') {
          peopleListAlumns.push(person)
        } else {
          peopleListCurrent.push(person)
        }
      }
      peopleListCurrent.forEach(function (a) {
        var role = a.role
        var name = a.name
        if (name === 'Jennifer Jacobs') {
          a.rank = 1
        } else if (role === 'faculty') {
          a.rank = 2
        } else if (role === 'student') {
          a.rank = 3
        } else if (role === 'staff') {
          a.rank = 4
        } else if (role === 'visiting researcher') {
          a.rank = 5
        } else {
          a.rank = 6
        }
      })
      peopleListCurrent.sort(function (a, b) {
        if (a.rank < b.rank) {
          return -1
        }
        if (a.rank > b.rank) {
          return 1
        }
        return 0
      })
      peopleList.push(peopleListCurrent)
      peopleList.push(peopleListAlumns)
      return peopleList
    }
  }
}
</script>
