var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container residency",attrs:{"id":"main"}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-3"},[_c('p',[_vm._v("The "),_c('router-link',{attrs:{"to":"/home"}},[_vm._v("Expressive Computation Lab")]),_vm._v(" at the "),_c('a',{attrs:{"href":"https://www.ucsb.edu/"}},[_vm._v("University of California, Santa Barbara")]),_vm._v(" is hosting an experimental ceramic craft residency, in collaboration with the "),_c('a',{attrs:{"href":"https://handandmachine.org/"}},[_vm._v("Hand and Machine Lab")]),_vm._v(" at the "),_c('a',{attrs:{"href":"https://www.unm.edu/"}},[_vm._v("University of New Mexico")]),_vm._v(". Our goal is to explore integrations of ceramic craft with new technologies. The resident will work with researchers to develop new work employing tools like ceramic 3D printers, laser cutters, and custom software.")],1),_c('p',[_vm._v("We are looking for experienced ceramic artists who are open to and interested in exploring new technologies. We are looking to partner with artists who are skilled craftspeople and whose work is clay focused. We are particularly interested in collaborating with artists who make functional work and artists who run a business based on their practice. No previous experience with digital technology is required or expected. We encourage artists with no previous digital experience to apply. We aim to support wide ranging explorations of the creative potential of new technologies in the hands of master craftspeople.")])])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-md-9 mt-4 mb-2",attrs:{"id":"title"}},[_c('h1',[_c('em',[_vm._v("EXPERIMENTAL")]),_vm._v(" CLAY RESIDENCY")])]),_c('div',{staticClass:"col-md-2 mt-4 mb-2 vertical_center affiliation"},[_c('div',{staticClass:"row flex-nowrap"},[_c('div',{staticClass:"col-md-3 mat_affiliation"},[_c('a',{attrs:{"href":"https://www.mat.ucsb.edu/"}},[_c('img',{attrs:{"src":require("../assets/images/MAT_logo_150x54.png")}})])]),_c('div',{staticClass:"col vertical_center logo_break"},[_vm._v("|")]),_c('div',{staticClass:"col-md-8 vertical_center ucsb_affiliation"},[_c('a',{attrs:{"href":"https://www.ucsb.edu/"}},[_c('img',{attrs:{"src":require("../assets/images/UC_Santa_Barbara_Wordmark_Black_RGB.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-5"},[_c('div',{attrs:{"id":"header_img"}},[_c('img',{attrs:{"src":require("../assets/images/ceramics_2023.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h2',[_vm._v("Residency Details")]),_c('hr')])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('h3',[_vm._v("When")])]),_c('div',{staticClass:"col-md-8"},[_c('p',{staticClass:"mb-1"},[_vm._v("The residency will occur for a duration of "),_c('b',[_vm._v("11 weeks")]),_vm._v(" between June 19th to September 1st, 2023.*")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('h3',[_vm._v("Submission Deadline")])]),_c('div',{staticClass:"col-md-8"},[_c('p',{staticClass:"mb-1 font-weight-bold"},[_vm._v("Applications must be completed by January 15th, 2023.")]),_c('p',[_vm._v("Selected recipents will be notified March 1st 2023.")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('h3',[_vm._v("Residency Support")])]),_c('div',{staticClass:"col-md-8"},[_c('p',[_vm._v("Selected residents will recieve the following:")]),_c('ul',[_c('li',[_vm._v("Monthly stipend totalling $20,000 for three months.")]),_c('li',[_vm._v("For artists who need to obtain housing or travel to and from UCSB, assistance with these costs.")]),_c('li',[_vm._v("Desk/Studio space in the Expressive Computation Research Lab in the "),_c('a',{attrs:{"href":"https://www.cnsi.ucsb.edu/"}},[_vm._v("California Nanosystems Institute.")])]),_c('li',[_vm._v("Access to the Expressive Computation Lab prototyping facilities that house a large electric kiln and a potter’s wheel as well as a PotterBot clay 3D printer, an Eazao Clay 3D printer, a laser cutter, several traditional 3D printers (FDM, SLA), and a UR-10 collaborative robot arm with a Lutum Eco Extruder.")]),_c('li',[_vm._v("Materials and supplies (clay, glazes, etc.) for the residency duration.")]),_c('li',[_vm._v("Access to technology and design researchers who will function as collaborators.")])]),_c('p',{staticClass:"small font-italic"},[_vm._v(" * For applicants with outside family, professional, or other commitments, we can work with you to develop a schedule that accommodates your needs.")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('h3',[_vm._v("Expectation of the Artist")])]),_c('div',{staticClass:"col-md-8"},[_c('p',[_vm._v("Selected artists are expected to:")]),_c('ul',[_c('li',[_vm._v("Collaborate with researchers to produce a body of work and accompanying processes that integrate ceramics with digital technology. ")]),_c('li',[_vm._v("Be available to work with researchers in-person at UCSB for the duration of the residency. This is an in-person residency. ")]),_c('li',[_vm._v("Be available to work at least 30 hours per week during the residency period. ")]),_c('li',[_vm._v("Participate in interviews and reflection sessions to help researchers document and characterize the nature of the collaborative process. ")]),_c('li',[_vm._v("Give an artist talk about your work during the residency. ")]),_c('li',[_vm._v("Participate in future exhibitions showcasing the outcomes of multiple residents over the course of the project. ")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('h3',[_vm._v("Selection Criteria")])]),_c('div',{staticClass:"col-md-8"},[_c('p',[_vm._v("Artists will be selected according to the following criteria:")]),_c('ul',[_c('li',[_vm._v("Portfolio")]),_c('li',[_vm._v("Residency proposal")]),_c('li',[_vm._v("Potential for mutually beneficial collaboration")]),_c('li',[_vm._v("Participants must be current residents of the United States.")]),_c('li',[_vm._v("Special consideration will be given to applicants from rural areas.")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('h3',[_vm._v("Application Requirements")])]),_c('div',{staticClass:"col-md-8"},[_c('p',[_vm._v("To apply please submit the following:")]),_c('ul',[_c('li',[_vm._v("A portfolio consisting of 10-15 images of recent work with accompanying descriptions and dates. This can be submitted in the form of a stand alone document or a link to an online portfolio.")]),_c('li',[_vm._v("A one-page artist statement.")]),_c('li',[_vm._v("A current resume or CV.")]),_c('li',[_vm._v(" A one-page residency statement. The statement should describe why you are interested in the residency and how you envision it impacting your ceramics practice. We welcome the inclusion of specific project ideas or information about technologies you are interested in exploring or learning about. The statement should also include a discussion of your approach to collaboration, touching on why you would like to explore this particular collaborative opportunity.")]),_c('li',[_vm._v("(Optional) Additional links to a professional website, instagram page, or other relevant site.")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('h3',[_vm._v("COVID-19")])]),_c('div',{staticClass:"col-md-8"},[_c('p',[_vm._v("All researchers involved in this project are fully vaccinated and the residency will be held in accordance with the latest (UCSB and California) COVID guidelines: "),_c('a',{attrs:{"href":"https://www.ucsb.edu/COVID-19-information"}},[_vm._v("ucsb.edu/COVID-19-information")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mt-4 mb-4 text-center"},[_c('a',{staticClass:"btn btn-outline-dark btn-lg",attrs:{"href":"https://airtable.com/shrj8LU4xXA1WFEkV","role":"button"}},[_vm._v("Apply Here")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h2',[_vm._v("Organizers")]),_c('hr')])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-md-12"},[_c('p',[_c('a',{attrs:{"href":"http://jenniferjacobs.mat.ucsb.edu"}},[_c('b',[_vm._v("Jennifer Jacobs")])]),_c('br'),_vm._v(" Director, Expressive Computation Lab"),_c('br'),_vm._v(" Assistant Professor of Media Arts and Technology, University of California, Santa Barbara ")]),_c('p',[_c('a',{attrs:{"href":"http://leahbuechley.com/"}},[_c('b',[_vm._v("Leah Buechley")])]),_c('br'),_vm._v(" Director, Hand and Machine Lab"),_c('br'),_vm._v(" Associate Professor of Computer Science, University of New Mexico ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-4"},[_c('h2',[_vm._v("Collaboration Team")]),_c('hr'),_c('p',[_vm._v("As a collaborator in the Expressive Computation Lab you will be working across engineering, art, and design. You will share immediate lab space with the students and faculty listed on the "),_c('a',{attrs:{"href":"https://ecl.mat.ucsb.edu/people"}},[_vm._v("people")]),_vm._v(" page on the Expressive Computation Lab website. ")]),_c('p',[_vm._v("The Expressive Computation team will be meeting remotely with Professor Buechley's Hand and Machine lab at UNM throughout the course of the residencies, providing an opportunity to share approaches and outcomes between the two teams.")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h2',[_vm._v("Selection Commitee")]),_c('hr'),_c('p',[_vm._v("The selection committee and organizers will work together to determine the finalists. The organizers will ultimately select the chosen resident.")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('p'),_c('p',[_c('a',{attrs:{"href":"https://avifarber.com"}},[_c('b',[_vm._v("Avi Farber")])]),_c('br'),_vm._v("2022 UCSB Resident"),_c('br')]),_c('p',[_c('a',{attrs:{"href":"https://pilarwiley.com"}},[_c('b',[_vm._v("Pilar Wiley")])]),_c('br'),_vm._v("2022 UCSB Resident"),_c('br')]),_c('p',[_c('a',{attrs:{"href":"https://camilafg.com/"}},[_c('b',[_vm._v("Camila Friedman-Gerlicz")])]),_c('br'),_vm._v("2022 UNM Resident"),_c('br')]),_c('p',[_c('a',{attrs:{"href":"https://www.instagram.com/jaredtsopottery/?hl=en"}},[_c('b',[_vm._v("Jared Tso")])]),_c('br'),_vm._v("2022 UNM Resident"),_c('br')]),_c('p',[_c('a',{attrs:{"href":"http://www.sarahrosalena.com/"}},[_c('b',[_vm._v("Sarah Rosalena Brady")])]),_c('br'),_vm._v("Assistant Professor of Computational Craft and Haptic Media in the Department of Art"),_c('br'),_vm._v(" University of California Santa Barbara")]),_c('p',[_c('a',{attrs:{"href":"https://saap.unm.edu/people/faculty/tim-b-castillo/index.html"}},[_c('strong',[_vm._v("Tim Castillo")])]),_c('br'),_vm._v("Professor of Architecture at the School of Architecture and Planning "),_c('br'),_vm._v("University of New Mexico")]),_c('p',[_c('a',{attrs:{"href":"https://www.mgt.unm.edu/faculty/facultyDetails.asp?id=10320"}},[_c('strong',[_vm._v("Manuel Montoya")])]),_c('br'),_vm._v("Associate Professor of Global Structures and International Management at the Anderson School of Management"),_c('br'),_vm._v("University of New Mexico")]),_c('p',[_c('strong',[_c('a',{attrs:{"href":"https://idpi.unm.edu/about/people/theodore-jojola.html"}},[_vm._v("Ted Jojola")])]),_c('br'),_vm._v("Director of the Indigenous Design and Planning Institute"),_c('br'),_vm._v("Distinguished Professor of Architecture at the School of Architecture and Planning"),_c('br'),_vm._v("University of New Mexico")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('hr'),_c('p',[_vm._v("This residency was made possible through a generous grant by the National Science Foundation, Award number: "),_c('a',{attrs:{"href":"https://www.nsf.gov/awardsearch/showAward?AWD_ID=2026286&HistoricalAwards=false"}},[_vm._v("2026286")]),_vm._v(". The funding will allow us to host this residency again in 2024.")]),_c('p',[_vm._v("Further questions? Contact "),_c('a',{attrs:{"href":"mailto:mat-ecl@ucsb.edu"}},[_vm._v("mat-ecl@ucsb.edu")]),_vm._v(".")])])])
}]

export { render, staticRenderFns }