<template>
    <div class = 'row'>
        <div class ='col-md-12 mb-3'>
            <div class="row">
        <div class ='col-md-12'>
         <h2 class = 'mb-3'><router-link :to='"/project/"+slug' class='button is-fullwidth'>{{title}}</router-link></h2>
        </div>
    </div>
            <div class = 'row mb-3'>
        <div class ='col-md-12'>
            <router-link :to='"/project/"+slug' class='button is-fullwidth'><img class = 'thumb' :src='thumb'></router-link>
        </div>
    </div>
    <div class="row">
        <div class ='col-md-12'>
            <p>{{snippet}}</p>
        </div>
    </div>
</div>
    </div>
</template>

<script>
export default {
  name: 'ProjectCard',
  props: {
    title: String,
    slug: String,
    date: String,
    abstract: String,
    snippet: String,
    thumb: String
  }
}
</script>
