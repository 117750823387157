import axios from 'axios'

const Axios = axios.create({
  baseURL: 'https://api.airtable.com/v0/appmca9NeevvksdW0/People'
})

const airtableAPIKey = process.env.VUE_APP_AIRTABLEKEY
Axios.defaults.headers.common = { Authorization: 'Bearer ' + airtableAPIKey }

export default {
  getPeople () {
    return Axios.get('?')
  },
  getPerson (slug) {
    return Axios.get("?filterByFormula={Name}='" + slug + "'")
  }
}
