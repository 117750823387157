<template>
  <main id="main">
  <!-- Header -->
  <div class="container py-4 py-lg-4">
    <div class = 'col-md-12'>
        <div class = 'row'>
            <div class = 'col-md-12 mb-4'>
                <div class = 'slideshow'>
    <VueSlickCarousel :arrows="false" :dots="true" :autoplay="true" :autoplaySpeed="5000" v-if="slideshow.length">
 <div v-for='slide in slideshow' :key = 'slide.image'><img :src='slide.image'/></div>

    </VueSlickCarousel>
  </div>
            </div>
        </div>
        <div class = 'row'>
            <div class = 'col-md-12 mb-3'>
                <h2>About the Lab</h2>
                <p>The Expressive Computation Lab (ECL) is a research group within the <a href='https://mat.ucsb.edu'>Media Arts and Technology Department</a> at the <a href='https://www.ucsb.edu/'>University of California Santa Barbara</a>. ECL's objective is to foster the development of expressive computational tools for art, design, and engineering. We envision a future for computational production where software and hardware tools are truly shaped by the people who use them. In line with this vision, ECL builds new computational tools that enable artists, designers, and engineers to integrate core computational affordances with their own knowledge and practices. We create new hardware and software interfaces that allow practitioners to combine computational abstraction, automation, and generativity with direct-manipulation, manual control, and physical materials. We develop domain-specific programming languages that allow practitioners to extend and modify software tools as they create. In the course of our research, we explore new methods to integrate iterative systems engineering with professional creative practices. The lab is based in the California NanoSystems Institute and is affiliated with the <a href= "https://ilab.cs.ucsb.edu/">Four Eyes Lab</a> in MAT and Computer Science and the Centers for Interactive and Visual Computing (CIVC) and <a href="https://ml.ucsb.edu/">Responsible Machine Learning</a> (CRML).</p>

                <h3>Interested in Applying?</h3>
                <p>See information for <router-link to="/openings">prospective students here</router-link></p>
            </div>
        </div>
        <div class = 'row'>
     <news></news>
     <div class = 'col-md-12 mb-5'><router-link to="/news">See all news</router-link></div>
   </div>
    </div>
  </div>
</main>
</template>

<script>
import News from '@/components/News'
import SlideshowService from '@/services/SlideshowService'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  name: 'home',

  components: {
    VueSlickCarousel,
    News
  },

  data () {
    return {
      airtableResponse: [],
      slideshowResponse: []
    }
  },

  mounted: function () {
    const self = this
    async function getSlideshow () {
      try {
        const response = await SlideshowService.getSlideshow()
        self.slideshowResponse = response.data.records
      } catch (err) {
        console.log(err)
      }
    }
    getSlideshow()
  },

  methods: {
    shuffle: function (array) {
      var currentIndex = array.length; var temporaryValue; var randomIndex

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1

        // And swap it with the current element.
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
      }

      return array
    }

  },

  computed: {
    slideshow () {
      const self = this
      const imageList = []
      for (var i = 0; i < self.slideshowResponse.length; i++) {
        if (self.slideshowResponse[i].fields.published) {
          const imageItem = {
            description: self.slideshowResponse[i].fields.description,
            alttext: self.slideshowResponse[i].fields.alttext,
            image: self.slideshowResponse[i].fields.image[0].url
          }
          imageList.push(imageItem)
        }
      }
      const shuffledList = self.shuffle(imageList)
      return shuffledList
    }
  }
}
</script>
