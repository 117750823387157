<template>
  <div class = 'col-md-12'>
    <div class = 'row'>
      <div class = 'col-md-12 mb-4'>
        <div id = 'header_img'><img src='../assets/images/home.png'/> </div>
      </div>
    </div>
    <div class = 'row'>
      <div class = 'col-md-12 mb-3'>
        <!--<h3>Artist / Craft Residencies </h3>
        <p>ECL is hosting an experimental clay residency during the Summer of 2023 to explore integrations of ceramic craft with digital fabrication.

        <p>We are looking for experienced ceramic artists who are open to and interested in exploring new technologies. No previous experience with digital technology is required or expected.</p>

        <p>See the full call <router-link to="/residency23">here</router-link></p>-->
        <h3>PhD Openings (MAT, CS)</h3>
        <p>ECL is seeking PhD students in Media Arts and Technology and/or Computer Science for the 2025-2026 academic year. Candidates should have a solid technical background and a strong interest in design, craft, or manufacturing applications of computation. We are particularly interested in students who have interests and skills in some combination of the following domains:</p>
        <ul>

          <li>Developing technologies and techniques that integrate computational design and digital fabrication.</li>
          <li>Developing new programming languages and environments for artists, designers, and craftspeople.</li>
          <li>Leveraging computer vision and robotics to support new manufacturing workflows.</li>
          <li>Large-scale digital fabrication techniques</li>
          <li>Efforts to broaden participation in computer science and computational production.</li>
          <li>Developing new authoring tools for immersive technologies (AR,VR,XR).</li>
          <li>Researching the social and cultural implications of emerging forms of technological production.</li>

        </ul>

        <h3>FAQ for PhD Applicants</h3>

        <p><b>Q: What kinds of educational backgrounds do you require for students who join your lab?</b></p>
        <p>A: There is no explicit requirement, but students with prior professional and/or educational experience in computer programming, electrical engineering, mechanical engineering, architecture, or a related field are generally more likely to be a good fit for the kind of research we pursue (e.g. engineering novel research systems)</p>

        <p><b>Q: What do students work on in your lab?</b></p>
        <p>We are primarily a Human-Computer Interaction research lab meaning that students work alone or in teams to build new interactive technologies and evaluate the performance of these technologies. Our primary output is research papers targeting top HCI publication venues, although we also explore art and design dissemination opportunities when possible. If your primary interest is in pursing an independent studio art practice, our lab is probably not a good fit. If you are interested in learning how to systematically conceive of, build, and assess new computational tools, techniques and workflows, and write about the results of this process, great! That is exactly what we do.</p>

        <p><b>Q: I'm interested in Artificial Intelligence. Is that a good fit with the research in your lab?</b></p>
         <p> A: We care a great deal about how computation can 1) assist professional practice, 2) open new opportunities for material and physical production, and 3) help people develop new skills and ways of understanding the world. If you have ideas for how AI technologies might connect to any of these goals, we're excited to hear them. We're somewhat less interested in how AI can replace or reproduce existing human skill-sets.</p>

         <p><b>Q: How many PhD students do you accept each year</b></p>
         <p>A: This varies based on funding and the current number of students, but generally our lab grows by 1-2 students per year based on graduation rates.</p>

        <p><b>Q: What do you look for in a PhD application?</b></p>
        <p>A: We assess all application materials holistically. Your statement of intent, portfolio, and personal statement are equally critical. A few tips: Your statement of intent should clearly lay out your research objectives for your PhD and how you see them connecting to ECL's current work and objectives. Stating specific project ideas is helpful for understanding how our objectives align with yours. Your portfolio should show your strongest creative and/or technical work to date. </p>

        <p><b>Q: Can I meet with you/ email you to discuss the program or receive feedback on my application?</b></p>
        <p>A: We receive many inquiries over email and I am unable to personally respond to them all. Requests for feedback that clearly state your interest in relation to the current and future research objectives of the group are helpful for assessing potential fit, but I cannot guarantee a response. I cannot provide you with feedback on your application materials prior to submission.</p>

        <p><b>Q: Can I apply to both MAT and CS?</b></p>
        <p>A: Unfortunately no, you must pick one</p>

        <p><b>Q: How do I apply?</b></p>
        <p>A: See the end of this page.</p>

        <h3>MS Openings (MAT, CS)</h3>

        <p>ECL is always looking for outstanding MS students to contribute to research in the lab.  <b>Current</b> UCSB Students interested in a research assistantship should email Professor Jacobs describing their background, experience, anticipated date of graduation, and research or career goals. Please also include a list of the courses you have taken (and grades received, unofficial transcripts preferred) and a resume. Attach copies of any papers that you have published in English.</p>

        <h3>All Candidates</h3>
        <p>
          We are especially interested in candidates who can contribute to the diversity and excellence of the academic community through research, teaching and service. The University of California is an Equal Opportunity/Affirmative Action Employer and all qualified applicants will receive consideration for employment without regard to race, color, religion, sex, sexual orientation, gender identity, national origin, disability status, protected veteran status, or any other characteristic protected by law.
        </p>

        <p>
          Prospective students should apply to the program that best aligns with their prior education. Individuals coming from interdisciplinary, art, or design backgrounds should apply to  Media Arts and Technology. Individuals from computer science should apply to either Media Arts and Technology or Computer Science. Please mention your interest in our lab and in working with Jennifer Jacobs in your application.
        </p>

        <p>
          For more information about the PhD program in Media Arts and Technology or Computer Science check out the <a href="https://www.mat.ucsb.edu/admissions/">MAT</a> and <a href="https://cs.ucsb.edu/education/grad/admissions">CS</a> department websites for admissions procedures.
        </p>
      </div>
    </div>

</div>
</template>

<script>
export default {
  name: 'contact'
}
</script>
