export default {
  getFormattedDate (date) {
    const dateArray = date.split('-')
    const day = dateArray[2]
    const month = dateArray[1]
    const year = dateArray[0]
    const d = new Date(month + '-' + day + '-' + year)
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
    const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
    const formattedDate = `${mo}/${da}/${ye}`
    return formattedDate
  },

  getNumericDate (date) {
    const dateArray = date.split('-')
    const day = dateArray[2]
    const month = dateArray[1]
    const year = dateArray[0]
    const d = new Date(month + '-' + day + '-' + year)
    return d
  }
}
