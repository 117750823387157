var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container residency",attrs:{"id":"main"}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-3"},[_c('p',[_vm._v("Do you enjoy embroidery, knitting, sewing or other textile crafts? Join us to make unique soft and colorful embroidered winter holiday crafts.")]),_c('p',[_vm._v("The "),_c('router-link',{attrs:{"to":"/home"}},[_vm._v("Expressive Computation Lab")]),_vm._v(" at the "),_c('a',{attrs:{"href":"https://www.ucsb.edu/"}},[_vm._v("University of California, Santa Barbara")]),_vm._v(" is hosting This two-day free workshop introduces participants to a new method for combining punch needle embroidery with 3D printing. We’ll show you how to design and make 3D printed patterns to create soft 3D crafts like ornaments, decorations, and jewelry.")],1)])]),_vm._m(2),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h3',[_vm._v("Lab Location")]),_c('p',[_vm._v(" The Expressive Computation Lab is housed in the "),_c('a',{attrs:{"href":"http://www.cnsi.ucsb.edu/"}},[_vm._v("California Nano-Systems Institute")]),_vm._v(" (Elings hall, Room 2024 — 2nd floor and left from the elevator) To contact an individual, see our "),_c('router-link',{attrs:{"to":"/people"}},[_vm._v("People")]),_vm._v(" page. ")],1),_c('h3',[_vm._v("Resources for Visitors")]),_vm._m(3)])])]),_vm._m(4)]),_c('div',{staticClass:"row"},[_vm._m(5),_c('div',{staticClass:"col-md-12"},[_c('p',[_vm._v("This workshop is a part of a research project looking at the intersection of textile craft and 3D printing. We are a lab interested in understanding the relationship between new forms of making and established craft traditions. You can learn more about our work at our "),_c('router-link',{attrs:{"to":"/home"}},[_vm._v("website")]),_vm._v(".")],1),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-md-9 mt-4 mb-2",attrs:{"id":"title"}},[_c('h1',[_c('em',[_vm._v("Experimental")]),_vm._v(" Embroidery Workshop")])]),_c('div',{staticClass:"col-md-2 mt-4 mb-2 vertical_center affiliation"},[_c('div',{staticClass:"row flex-nowrap"},[_c('div',{staticClass:"col-md-3 mat_affiliation"},[_c('a',{attrs:{"href":"https://www.mat.ucsb.edu/"}},[_c('img',{attrs:{"src":require("../assets/images/MAT_logo_150x54.png")}})])]),_c('div',{staticClass:"col vertical_center logo_break"},[_vm._v("|")]),_c('div',{staticClass:"col-md-8 vertical_center ucsb_affiliation"},[_c('a',{attrs:{"href":"https://www.ucsb.edu/"}},[_c('img',{attrs:{"src":require("../assets/images/UC_Santa_Barbara_Wordmark_Black_RGB.png")}})])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-3"},[_c('div',{attrs:{"id":"header_img"}},[_c('img',{attrs:{"src":require("../assets/images/72ppi/embroidery_header.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('h2',[_vm._v("Details and Registration")]),_c('hr')]),_c('div',{staticClass:"col-md-12 mb-3"},[_c('p',[_c('b',[_vm._v("When:")]),_vm._v(" Saturday December 3rd and Saturday December 10th from 1pm to 4pm")]),_c('p',[_c('b',[_vm._v("Where:")]),_vm._v(" Elings Hall - Building 266, Room 2024 (See below for directions)")]),_c('p',[_vm._v("The workshop is free of charge and all materials will be provided. You must be 18 years or older, and available for both days of the workshop to participate.")]),_c('p',{staticClass:"highlightcolor"},[_vm._v("Workshop is currently full. Email "),_c('a',{attrs:{"href":"mailto:mat-ecl@ucsb.edu"}},[_vm._v("mat-ecl@ucsb.edu")]),_vm._v(" to be added to the waitlist.")]),_c('p',{staticClass:"strikethrough"},[_c('b',[_vm._v("Space is limited. To enroll complete and submit this form via the web: "),_c('a',{attrs:{"href":"https://bit.ly/experimental-embroidery"}},[_vm._v("bit.ly/experimental-embroidery")])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"http://www.aw.id.ucsb.edu/maps"}},[_vm._v("UCSB campus and area maps")])]),_c('li',[_c('a',{attrs:{"href":"http://www.cs.ucsb.edu/department/visiting/"}},[_vm._v("Visitor information (travel to UCSB, parking, lodging, etc.)")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"map"},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3291.4387294308217!2d-119.84240338477535!3d34.41560938050866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e93f76c59b6817%3A0x73191f17be4fc14d!2sElings%20Hall%2C%20Building%20266%2C%20University%20of%20California%2C%20Santa%20Barbara%2C%20Santa%20Barbara%2C%20CA%2093106!5e0!3m2!1sen!2sus!4v1607406377925!5m2!1sen!2sus","width":"100%","height":"450","frameborder":"0","allowfullscreen":"","aria-hidden":"false","tabindex":"0"}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-12"},[_c('h2',[_vm._v("About Us")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v("Contact "),_c('a',{attrs:{"href":"mailto:adelvalle@ucsb.edu"}},[_vm._v("adelvalle@ucsb.edu")]),_vm._v(" or call 805-893-3015 if you have questions.")])
}]

export { render, staticRenderFns }