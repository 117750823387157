<template>
  <main id="main">
  <!-- Header -->
    <div class="container py-4 py-lg-4 "><h1 class="display-5 mb-2">Design and Research Porfolio</h1>
  <div class = "row">
  <div class="col-lg-8 col-xxl-8 font-size-lg">

  <p>Below I have compiled a selection of multi-year projects aimed at fostering the growth of diverse and domain-specific computational technologies for making.</p>
</div>
</div>
    </div>

     <div class="container py-4 py-lg-4 ">
        <div class="row">
        <div class = 'col-md-6' v-for='project in projects' :key="project.title">
              <project-card v-bind="project"></project-card>
        </div>
      </div>
    </div>
</main>
</template>

<style type="text/css">

</style>

<script>
import ProjectsService from '@/services/ProjectsService'
import ProjectCard from '@/components/ProjectCard'
// import json from '@/assets/data/projects.json'

export default {
  name: 'projects',
  components: {
    ProjectCard
  },

  data () {
    return {
      airtableResponse: []
    }
  },

  mounted: function () {
    const self = this
    async function getProjects () {
      try {
        const response = await ProjectsService.getProjects()
        self.airtableResponse = response.data.records
      } catch (err) {
        console.log(err)
      }
    }
    getProjects()
  },

  computed: {
    projects () {
      const self = this
      const projectList = []
      for (var i = 0; i < self.airtableResponse.length; i++) {
        if (self.airtableResponse[i].fields.published) {
          const project = {
            title: self.airtableResponse[i].fields.title,
            abstract: self.airtableResponse[i].fields.abstract,
            authors: self.airtableResponse[i].fields.author,
            slug: self.airtableResponse[i].fields.slug,
            snippet: self.airtableResponse[i].fields.snippet,
            thumb: self.airtableResponse[i].fields.thumb[0].url
          }
          projectList.push(project)
        }
      }
      return projectList
    }
  }
}
</script>
