import axios from 'axios'

const Axios = axios.create({
  baseURL: 'https://api.airtable.com/v0/appmca9NeevvksdW0/Slideshow'
})

const airtableAPIKey = process.env.VUE_APP_AIRTABLEKEY
Axios.defaults.headers.common = { Authorization: 'Bearer ' + airtableAPIKey }

export default {
  getSlideshow () {
    const slideshow = Axios.get('?')
    return slideshow
  }
}
