c<template>
  <main id="main">
  <!-- Header -->
  <div class="container py-4 py-lg-4">
    <div class = 'col-md-12'>
        <div class="row" v-for='project in projects' :key="project.title">
              <pub-card v-bind="project"></pub-card>
        </div>
    </div>
  </div>
</main>
</template>

<style type="text/css">

</style>

<script>
import PubsService from '@/services/PubsService'
import PubCard from '@/components/PubCard'
// import json from '@/assets/data/projects.json'

export default {
  name: 'publications',
  components: {
    PubCard
  },

  data () {
    return {
      airtableResponse: []
    }
  },

  mounted: function () {
    const self = this
    async function getProjects () {
      try {
        const response = await PubsService.getProjects()
        self.airtableResponse = response.data.records
      } catch (err) {
        console.log(err)
      }
    }
    getProjects()
  },

  computed: {
    projects () {
      const self = this
      const projectList = []
      for (var i = 0; i < self.airtableResponse.length; i++) {
        if (self.airtableResponse[i].fields.published === true && self.airtableResponse[i].fields.status !== 'confidential') {
          const project = {
            title: self.airtableResponse[i].fields.title,
            authors: self.airtableResponse[i].fields.author,
            slug: self.airtableResponse[i].fields.slug,
            month: self.airtableResponse[i].fields.month,
            year: self.airtableResponse[i].fields.year,
            date: new Date('1 ' + self.airtableResponse[i].fields.month + ' ' + self.airtableResponse[i].fields.year),
            pdf: (self.airtableResponse[i].fields.pdf ? self.airtableResponse[i].fields.pdf[0].url : null),
            doi: self.airtableResponse[i].fields.doi,
            venue: self.airtableResponse[i].fields.venue,
            bibtex: self.airtableResponse[i].fields.bibtex,
            thumb: self.airtableResponse[i].fields.thumb[0].url,
            pagelink: self.airtableResponse[i].fields.pagelink,
            web: [],
            status: self.airtableResponse[i].fields.status
          }

          if (self.airtableResponse[i].fields.pdf) {
            project.web.push({
              link: self.airtableResponse[i].fields.pdf[0].url,
              text: 'PDF'
            })
          }
          if (self.airtableResponse[i].fields.doi) {
            project.web.push({
              link: self.airtableResponse[i].fields.doi,
              text: 'DOI'
            })
          }
          if (self.airtableResponse[i].fields.talk) {
            project.web.push({
              link: self.airtableResponse[i].fields.talk,
              text: 'Talk'
            })
          }
          if (self.airtableResponse[i].fields.bibtex) {
            project.web.push({
              link: self.airtableResponse[i].fields.bibtex,
              text: 'BibTeX'
            })
          }
          if (self.airtableResponse[i].fields.website) {
            project.web.push({
              link: self.airtableResponse[i].fields.website,
              text: 'website'
            })
          }
          if (self.airtableResponse[i].fields.bestpaper) {
            project.award = 'Best Paper Award'
          } else if (self.airtableResponse[i].fields.honorablemention) {
            project.award = 'Best Paper Honorable Mention'
          } else if (self.airtableResponse[i].fields.bestdemo) {
            project.award = 'Best Demo Award'
          } else {
            project.award = ''
          }
          projectList.push(project)
        }
      }

      projectList.sort(function (a, b) {
        if (a.date > b.date) {
          return -1
        } else {
          return 1
        }
      })

      return projectList
    }
  }
}
</script>
