<template>
    <div class = 'row bio'>
        <div class ='col-md-12'>
            <div class="row">
                <div class = 'col-md-12 mb-2'>
                    <img class="bio" :src = 'image'>
                </div>
            </div>
            <div class = 'row'>
                 <div class = 'col-md-12'>
                   <a class = 'bio' v-b-popover.hover='bio'>
                    <h3>{{name}}</h3></a>
                    <p>{{title}}</p>
                    <p><a/>
                        <span v-for='(r,index) in research' :key='r'>
                            <em>{{r}}</em>
                        <span v-if="index <= research.length - 2">, </span>
                    </span>
                    </p>
                    <p>
                        <span v-for='w in web' :key='w.link'>
                            <a :href='w.link'>
                                <span v-if='w.text == "email"'>
                                    <md-mail-icon w='20' h='20'/> &nbsp;
                                </span>
                                <span v-if='w.text == "website"'>
                                    <md-document-icon w='20' h='20'/> &nbsp;
                                </span>
                                <span v-if='w.text == "twitter"'>
                                    <logo-twitter-icon w='20' h='20'/> &nbsp;
                                </span>
                                <span v-if='w.text == "instagram"'>
                                    <logo-instagram-icon w='20' h='20'/> &nbsp;
                                </span>
                                <span v-if='w.text == "linkedin"'>
                                    <logo-linkedin-icon w='20' h='20'/> &nbsp;
                                </span>
                            </a>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'PeopleCard',

  props: {
    name: String,
    title: String,
    image: String,
    role: String,
    website: String,
    email: String,
    bio: String,
    research: {
      type: Array
    },
    web: {
      type: Array
    }
  }
}
</script>
