import axios from 'axios'

const Axios = axios.create({
  baseURL: 'https://api.airtable.com/v0/appmca9NeevvksdW0'
})

const airtableAPIKey = process.env.VUE_APP_AIRTABLEKEY
Axios.defaults.headers.common = { Authorization: 'Bearer ' + airtableAPIKey }

export default {

  getAuthors () {
    return Axios.get('/Authors?')
  },
  getAuthorsById (authorIdList) {
    const authorIdString = "OR( RECORD_ID() = '" + authorIdList.join("', RECORD_ID() = '") + "')"
    return Axios.get('/Authors?filterByFormula=' + authorIdString)
  },

  async getAuthorList (authors) {
    const self = this
    try {
      const response = await self.getAuthorsById(authors)
      const airtableAuthorResponse = response.data.records
      return airtableAuthorResponse
    } catch (err) {
      console.log(err)
    }
  },

  setupAuthor (response) {
    const author = {
      name: response.fields.name,
      institution: response.fields.institution,
      url: response.fields.url,
      id: response.id
    }
    return author
  },

  sortAuthorList (authorList, sorting) {
    var authorResult = authorList.map(function (author) {
      var n = sorting.indexOf(author.id)
      sorting[n] = ''
      return [n, author]
    }).sort().map(function (j) { return j[1] })
    return authorResult
  },

  getCourses () {
    return Axios.get('/Courses?')
  },
  getCourse (slug) {
    return Axios.get("/Courses?filterByFormula={slug}='" + slug + "'")
  },

  getPapers () {
    return Axios.get('/Papers?')
  },
  getPaper (slug) {
    return Axios.get("/Papers?filterByFormula={slug}='" + slug + "'")
  },
  getPapersById (paperIdList) {
    const paperIdString = "OR( RECORD_ID() = '" + paperIdList.join("', RECORD_ID() = '") + "')"
    return Axios.get('/Papers?filterByFormula=' + paperIdString)
  },

  getMediaById (mediaIdList) {
    const mediaIdString = "OR( RECORD_ID() = '" + mediaIdList.join("', RECORD_ID() = '") + "')"
    return Axios.get('/Media?filterByFormula=' + mediaIdString)
  },

  getNews () {
    const news = Axios.get('/News?')
    return news
  },
  getPeople () {
    return Axios.get('/People?')
  },
  getPerson (slug) {
    return Axios.get("/People?filterByFormula={Name}='" + slug + "'")
  },
  getProjects () {
    return Axios.get('/Projects?')
  },
  getProject (slug) {
    return Axios.get("/Projects?filterByFormula={slug}='" + slug + "'")
  },
  getEvents (slug) {
    return Axios.get('/Events?')
  },
  getEvent (slug) {
    return Axios.get("/Events?filterByFormula={slug}='" + slug + "'")
  },
  getSlideshow () {
    const slideshow = Axios.get('/Slideshow?')
    return slideshow
  }

}
