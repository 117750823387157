<template>
  <main id="main">
  <!-- Header -->
  <div class="container py-4 py-lg-4">
    <div class = 'col-md-12'>
        <div class="row" v-for='event in events' :key="event.title">
              <event-card v-bind="event"></event-card>
        </div>
    </div>
  </div>
</main>
</template>

<style type="text/css">

</style>

<script>
import AirtableService from '@/services/AirtableService'
import EventCard from '@/components/EventCard'
// import json from '@/assets/data/projects.json'

export default {
  name: 'events',
  components: {
    EventCard
  },

  data () {
    return {
      airtableResponse: []
    }
  },

  mounted: function () {
    const self = this
    async function getEvents () {
      try {
        const response = await AirtableService.getEvents()
        self.airtableResponse = response.data.records
      } catch (err) {
        console.log(err)
      }
    }
    getEvents()
  },

  computed: {
    events () {
      const self = this
      const events = []
      for (var i = 0; i < self.airtableResponse.length; i++) {
        if (self.airtableResponse[i].fields.published) {
          const project = {
            title: self.airtableResponse[i].fields.title,
            snippet: self.airtableResponse[i].fields.snippet,
            authors: self.airtableResponse[i].fields.collaborators,
            slug: self.airtableResponse[i].fields.slug,
            startDate: self.airtableResponse[i].fields.startDate,
            thumb: self.airtableResponse[i].fields.media[0].url
          }
          events.push(project)
        }
      }
      return events
    }
  }
}
</script>
