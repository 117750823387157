<template>
        <router-view> </router-view>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      showNav: true,
      logo: require('./assets/images/logo.png')
    }
  },

  metaInfo () {
    return {
      title: 'Expressive Computation Lab',
      htmlAttrs: {
      },
      headAttrs: {
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'ECL is a research lab in Media Arts and Technology at the University of California Santa Barbara' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: 'Expressive Computation Lab' },
        { name: 'twitter:description', content: 'The Expressive Computation Lab is a research group within the Media Arts and Technology Department at the University of California Santa Barbara. We develop computational tools for art, design, and engineering.' },
        // image must be an absolute path
        { name: 'twitter:image', content: this.logo }
        // Facebook OpenGraph
        /* { property: 'og:title', content: 'Vue Social Cards Example' },
        { property: 'og:site_name', content: 'Vue Example' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: this.logo },
        { property: 'og:description', content: 'Vue sample site showing off Twitter and Facebook Cards.'
        } */
      ]
    }
  },

  mounted: function () {
    const path = localStorage.getItem('path')
    if (path) {
      localStorage.removeItem('path')
      this.$router.push(path)
    }
  }
}
</script>

<style>

</style>
