<template>
<main id="main">
  <!-- Header -->
  <div class="container py-4 py-lg-4">
    <h1 class="display-5 ">{{project.title}}</h1>
  </div>

  <!-- Project -->
  <div class="bg-white text-dark pb-5">
    <div class="container-xl py-2 px-0 mb-5">
     <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="6000"
      controls
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <template v-for= 'media in project.abstractMedia.assets'>
      <b-carousel-slide :key='media.id'
        :img-src= 'media.url'
      ></b-carousel-slide>
    </template>
    </b-carousel>
    </div>
    <div class="container">
      <div class="row mb-5">
        <div class="col-lg-4">
          <dl class="mt-1">
            <dt class="fw-light">Years</dt>
            <dd>{{project.startDate}}-{{project.endDate}}</dd>
            <dt class="fw-light">Collaborators</dt>
            <dd>
              <template v-for='(collaborator,index) in project.collaborators'>
                <a :key='collaborator.id' :href='collaborator.url'>{{collaborator.name}}<span v-if='index < project.collaborators.length - 1'>, </span></a>
              </template>
            </dd>
            <dt class="fw-light">Publications</dt>
            <dd>
              <template v-for='(paper,index) in project.papers'>
                <router-link :key='paper.id' :to='"/publication/"+paper.slug'>
                  {{paper.shortName}}<span v-if='index < project.papers.length - 1'>, </span>
                </router-link>
              </template>
            </dd>
          </dl>
        </div>
        <div class="col-lg-8 col-xxl-7">
          <vue-simple-markdown :source='project.abstract'></vue-simple-markdown>
        </div>
      </div>
      <template v-for='media in project.media'>
          <div class="row mb-2 py-1" :key= 'media.title'>
            <div class="col-lg-12 col-xxl-12">
              <h2 v-if= 'media.title' class='mb-3'>{{media.title}}</h2>
              <div class = "row mb-3">
                <div class="col-lg-12 col-xxl-12">
                  <vue-simple-markdown :source='media.bodyText'></vue-simple-markdown>
                </div>
              </div>
              <div class = "row">
                <template v-for='(asset,index) in media.assets'>
                  <div class = 'col-lg-4' :key = 'asset.id+index'>
                    <picture v-b-modal='"modal_"+asset.id'>
                   <img class="mb-3" :src= 'asset.url'/>
                   </picture>
                   <b-modal size="xl" hide-footer="true" ok-only="true" :id='"modal_"+asset.id'><img :src= 'asset.url'/></b-modal>
                  </div>
                </template>
              </div>
              <div class = "row">
                <div class="col-lg-12 col-xxl-12 text-left">
                  <figure class="figure">
                   <figcaption class="figure-caption"><vue-simple-markdown :source='media.description'></vue-simple-markdown></figcaption>
                 </figure>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!--<template v-for='media in project.media'>
          <div class="row mb-4 py-1" :key= 'media.title'>
            <div class="col-lg-12 col-xxl-12">
              <h2 v-if= 'media.title' class='mb-3'>{{media.title}}</h2>
              <div class = "row mb-3">
                <template v-for='(asset,index) in media.assets'>
                  <div class = 'col-lg-4 px-1' :key = 'asset.id+index'>
                   <img :src= 'asset.url'/>
                  </div>
                </template>
              </div>
              <div class = "row">
                <div class="col-lg-12 col-xxl-12 text-center">
                  <figure class="figure">
                   <figcaption class="figure-caption">{{media.description}}</figcaption>
                 </figure>
                </div>
              </div>
              <div class = "row">
                <div class="col-lg-12 col-xxl-12">
                  <vue-simple-markdown :source='media.bodyText'></vue-simple-markdown>
                </div>
              </div>
            </div>
          </div>
        </template>-->

      <!-- Projects navigation -->
      <div class="d-flex justify-content-between align-content-center lh-1">
        <router-link to="/projects">
          <span class="visually-hidden">Back to all projects</span>
        </router-link>

      </div>
    </div>
  </div>
</main>
</template>

<script>
import AirtableService from '@/services/AirtableService'

export default {
  name: 'project',
  data () {
    return {
      airtableProjectResponse: [],
      airtableAuthorResponse: [],
      airtablePapersResponse: [],
      airtableMediaResponse: [],
      slide: 0,
      sliding: null
    }
  },

  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    }
  },

  mounted: function () {
    const self = this
    async function getProject () {
      try {
        const response = await AirtableService.getProject(self.$route.params.slug)
        const records = response.data.records
        self.airtableProjectResponse = records
        if (records.length > 0) {
          const authors = records[0].fields.collaborators
          try {
            const response = await AirtableService.getAuthorsById(authors)
            self.airtableAuthorResponse = response.data.records
          } catch (err) {
            console.log(err)
          }
          const papers = records[0].fields.papers
          try {
            const response = await AirtableService.getPapersById(papers)
            self.airtablePapersResponse = response.data.records
          } catch (err) {
            console.log(err)
          }
          const media = records[0].fields.media
          try {
            const response = await AirtableService.getMediaById(media)
            self.airtableMediaResponse = response.data.records
          } catch (err) {
            console.log(err)
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    getProject()
  },
  computed: {
    project () {
      const self = this
      var authorList = []
      for (var i = 0; i < self.airtableAuthorResponse.length; i++) {
        const author = {
          name: self.airtableAuthorResponse[i].fields.name,
          url: self.airtableAuthorResponse[i].fields.url,
          id: self.airtableAuthorResponse[i].id
        }
        authorList.push(author)
      }
      var papersList = []
      for (var j = 0; j < self.airtablePapersResponse.length; j++) {
        const paper = {
          shortName: self.airtablePapersResponse[j].fields.shortName,
          slug: self.airtablePapersResponse[j].fields.slug,
          id: self.airtablePapersResponse[j].fields.id,
          year: self.airtablePapersResponse[j].fields.year
        }
        papersList.push(paper)
      }
      var abstractMedia
      var mediaList = []
      for (var k = 0; k < self.airtableMediaResponse.length; k++) {
        const media = {
          title: self.airtableMediaResponse[k].fields.Title,
          assets: self.airtableMediaResponse[k].fields.asset,
          id: self.airtableMediaResponse[k].fields.id,
          description: self.airtableMediaResponse[k].fields.description,
          bodyText: self.airtableMediaResponse[k].fields.bodyText,
          order: self.airtableMediaResponse[k].fields.order
        }
        if (self.airtableMediaResponse[k].fields.abstract === true) {
          abstractMedia = media
        } else {
          mediaList.push(media)
        }
      }

      if (self.airtableProjectResponse[0]) {
        const sorting = self.airtableProjectResponse[0].fields.collaborators
        var authorResult = authorList.map(function (author) {
          var n = sorting.indexOf(author.id)
          sorting[n] = ''
          return [n, author]
        }).sort().map(function (j) { return j[1] })

        var papersResult = papersList.sort((a, b) => (Number(a.year) > Number(b.year)) ? 1 : -1)
        var mediaResult = mediaList.sort((a, b) => (Number(a.order) > Number(b.order)) ? 1 : -1)

        const thisProject = {
          title: self.airtableProjectResponse[0].fields.title,
          abstract: self.airtableProjectResponse[0].fields.abstract,
          papers: papersResult,
          collaborators: authorResult,
          startDate: self.airtableProjectResponse[0].fields.startDate,
          endDate: self.airtableProjectResponse[0].fields.endDate,
          abstractMedia: abstractMedia,
          media: mediaResult
        }
        return thisProject
      }
      return {}
    }
  }
}
</script>
