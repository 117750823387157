<template>
<main id="main">
  <!-- Header -->
  <div class="container py-4 py-lg-3">
    <h1 class="display-5 ">{{project.title}}</h1>
  </div>

  <!-- Project -->
  <div class="bg-white text-dark pb-5">
    <div class="container-xl py-2 px-0 mb-5">
     <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="2000"
      controls
      background="#ababab"
      img-width="1024"
      img-height="300"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <template v-for= 'media in project.media'>
      <b-carousel-slide :key='media.id'
        :img-src= 'media.url'
      ></b-carousel-slide>
    </template>
    </b-carousel>
    </div>
    <div class="container">
      <div class="row mb-5">
        <div class="col-lg-4">
          <dl class="mt-1">
            <dt class="fw-light">Date</dt>
            <dd>{{project.startDate}}<span v-if=project.endDate>-{{project.endDate}}</span></dd>
            <dt class="fw-light">Time</dt>
            <dd>{{project.time}}</dd>
            <dt class="fw-light">Location</dt>
            <dd><a :href=project.locationURL>{{project.location}}</a></dd>
            <dt class="fw-light">Organizers</dt>
            <dd>
              <template v-for='(collaborator,index) in project.collaborators'>
                <a :key='collaborator.id' :href='collaborator.url'>{{collaborator.name}}<span v-if='index < project.collaborators.length - 1'>, </span></a>
              </template>
            </dd>
            <dt v-if=project.apply class="fw-light">Application Details</dt>
            <dd v-if=project.apply><a :href=project.apply>Application form here</a></dd>
            <dt class="fw-light">Contact</dt>
            <dd><a :href=project.contact_mail>{{project.contact}}</a></dd>
          </dl>
        </div>
        <div class="col-lg-8 col-xxl-7">
          <vue-simple-markdown :source='project.abstract'></vue-simple-markdown>
        </div>
      </div>
    </div>
  </div>
</main>
</template>

<script>
import AirtableService from '@/services/AirtableService'
export default {
  name: 'project',
  data () {
    return {
      airtableProjectResponse: [],
      airtableAuthorResponse: [],
      airtablePapersResponse: [],
      airtableMediaResponse: [],
      slide: 0,
      sliding: null
    }
  },

  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    }
  },

  mounted: function () {
    const self = this
    async function getProject () {
      try {
        const response = await AirtableService.getEvent(self.$route.params.slug)
        const records = response.data.records
        self.airtableProjectResponse = records
        if (records.length > 0) {
          const authors = records[0].fields.collaborators
          try {
            const response = await AirtableService.getAuthorsById(authors)
            self.airtableAuthorResponse = response.data.records
          } catch (err) {
            console.log(err)
          }
          const media = records[0].fields.media
          try {
            const response = await AirtableService.getMediaById(media)
            self.airtableMediaResponse = response.data.records
          } catch (err) {
            console.log(err)
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    getProject()
  },
  computed: {
    project () {
      const self = this
      var authorList = []
      for (var i = 0; i < self.airtableAuthorResponse.length; i++) {
        const author = {
          name: self.airtableAuthorResponse[i].fields.name,
          url: self.airtableAuthorResponse[i].fields.url,
          id: self.airtableAuthorResponse[i].id
        }
        authorList.push(author)
      }
      if (self.airtableProjectResponse[0]) {
        const sorting = self.airtableProjectResponse[0].fields.collaborators
        var authorResult = authorList.map(function (author) {
          var n = sorting.indexOf(author.id)
          sorting[n] = ''
          return [n, author]
        }).sort().map(function (j) { return j[1] })
        const thisProject = {
          title: self.airtableProjectResponse[0].fields.title,
          abstract: self.airtableProjectResponse[0].fields.abstract,
          collaborators: authorResult,
          startDate: self.airtableProjectResponse[0].fields.startDate,
          endDate: self.airtableProjectResponse[0].fields.endDate,
          time: self.airtableProjectResponse[0].fields.time,
          location: self.airtableProjectResponse[0].fields.location,
          locationURL: self.airtableProjectResponse[0].fields.locationURL,
          media: self.airtableProjectResponse[0].fields.media,
          apply: self.airtableProjectResponse[0].fields.apply,
          contact: self.airtableProjectResponse[0].fields.contact,
          contact_mail: 'mailto:' + self.airtableProjectResponse[0].fields.contact
        }
        return thisProject
      }
      return {}
    }
  }
}
</script>
